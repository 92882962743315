export function copyToClipboard(content) {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.setAttribute('readonly', 'readonly');
    input.setAttribute('value', content);
    document.body.appendChild(input);
    input.select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
      // console.log('复制成功');
      resolve();
    } else {
      // console.log('复制失败');
      reject();
    }
    document.body.removeChild(input);
    // console.log('复制操作，删除元素成功');
  })
}

<template>
  <div class="wrap">
<!--    <div class="header">
      售前咨询
    </div>-->
    <div class="list">
      <div class="select-box">
        <van-cell is-link arrow-direction="down" @click="showPicker = true" >
          <template #title>
            <div class="goods">
              <div class="image">
                <img v-if="currentGoods && currentGoods.image" :src="currentGoods.image" alt="">
              </div>
              <div class="title">
                {{ currentGoods && currentGoods.title ? currentGoods.title : '全部商品' }}
              </div>
            </div>
          </template>
        </van-cell>
      </div>
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getData"
      >
        <div class="item" v-for="question in questions" :key="question.questionId" @click="toQuestionDetailPage(question.questionId)">
          <div class="image" v-is-catalog="question.goods.catalogProductId" catalog-img-width="2rem">
            <img v-if="question.goods" :src="question.goods.image" alt="">
          </div>
          <div class="info">
            <div class="title">
              {{ question.text }}
            </div>
            <div class="other">
              <div class="name">{{ question.accountInfo ? question.accountInfo.name : '???' }}</div>
              <div class="date">{{ question.dateCreated }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>

    <van-popup v-model="showPicker" position="bottom" :style="{ height: '50%' }" class="goods-picker" >
      <van-picker
          title="请选择商品"
          show-toolbar
          :columns="showAllGoods"
          @confirm="changeGoods"
          @cancel="showPicker = false"
      >
        <template #option="goods">
          <div class="goods">
            <div class="image">
              <img v-if="goods && goods.image" :src="goods.image" alt="">
            </div>
            <div class="title">{{ goods.title }}</div>
          </div>
        </template>
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
import {getQuestionList} from "@/api/question";

export default {
  data() {
    return {
      page: 1,
      pageSize: 10,
      questions: [],
      loading: false,
      finished: false,
      goodsId: '',
      goodsData: [],
      showPicker: false,
    }
  },
  computed: {
    currentGoods: function () {
      let currentGoods = null
      for (let i = 0; i < this.showAllGoods.length; i++) {
        if (this.showAllGoods[i].goodsId === this.goodsId) {
          currentGoods = this.showAllGoods[i];
        }
      }
      return currentGoods;
    },
    showAllGoods: function () {
      const defaultAll = {
        goodsId: '',
        title: '全部商品',
        status: 'active',
        image: '',
      };

      return [defaultAll, ...this.goodsData];
    }
  },
  created() {
  },
  methods: {
    getData() {
      const handle = this.$route.query.handle;
      getQuestionList(handle, this.goodsId, this.page, this.pageSize)
        .then(res => {
          this.loading = false;
          if (res.error === 0) {
            if (res.data.length) {
              this.questions = [...this.questions, ...res.data];
              this.goodsData = res.info.goodsData;
              this.page++;
            } else {
              this.finished = true;
            }
          } else {
            this.finished = true;
            this.$errorMessage(res.message);
          }
        })
        .catch(error => {
          this.loading = false;
          this.finished = true;
          console.log('请求问题列表失败', error)
        })
    },
    toQuestionDetailPage(questionId) {
      this.$router.push({
        name: 'questionDetail',
        params: {
          questionId,
        }
      });
    },
    changeGoods(goods) {
      // console.log('goods', goods);
      this.goodsId = goods.goodsId;
      this.showPicker = false;

      // 重置数据
      this.questions = [];
      this.page = 1;

      this.getData();
    }

  }
}
</script>

<style lang="less" scoped>
  .header{
    font-size: 1.2rem;
    padding: .5rem 0;
    color: #000000;
    background-color: #f2f2f2;
    font-weight: bold;
  }
  .list{
    padding: 0 .8rem;
    .select-box{
      border:1px solid rgba(233, 234, 239, 1);
      border-radius: .5rem;
      color: #7F7F7F;
      margin: 12px 0 12px;
      .goods{
        text-align: left;
        display: flex;
        width: 100%;
        overflow: hidden;
        align-items: center;
        .image{
          width: 30px;
          height: 30px;
          flex: 0 0 auto;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .title{
          padding-left: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .item{
      display: flex;
      border-bottom: 1px solid #F5F7FA;
      padding: .6rem 0;
      .image{
        flex: 0 0 auto;
        width: 3.5rem;
        height: 3.5rem;
        background-color: #666666;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .info{
        flex: 1 1 auto;
        font-size: .8rem;
        padding-left: 10px;
        .title{
          text-align: left;
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          height: 2rem;
          line-height: 1rem;
        }
        .other{
          padding: .5rem 0 0;
          display: flex;
          justify-content: space-between;
          color: #999999;
        }
      }
    }
  }
  .goods-picker{
    .goods{
      padding:0 20px;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      .image{
        width: 28px;
        height: 28px;
        flex: 0 0 auto;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .title{

      }
    }
  }
</style>

<style lang="less">
.select-box{
  .van-cell{
    padding: 5px 8px;
    justify-content: space-between;
    align-items: center;
    .van-cell__title{
      width: 88%;
      flex: 0 0 auto;
    }
  }
}
</style>

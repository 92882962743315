export default {
  install(Vue) {
    Vue.directive('is-catalog', {
      bind(el, binding, vnode) {
        if (binding.value) {
          // 设置父元素样式
          el.style.position = 'relative';
          // 创建图片元素
          const img = document.createElement('img');

          img.src = require('@/assets/images/is-catalog.png');  // 在这里替换成你的图片路径
          img.style.width = el.getAttribute('catalog-img-width') || '20px';
          img.style.position = 'absolute';
          img.style.top = '0';
          img.style.left = '0'
          img.className = 'catalog-goods-img';

          el.appendChild(img)
        }
      }
    })
  },
}

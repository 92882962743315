<template>
  <div class="question-history-wrap">
    <history
        width="100%"
        height="100%"
        :customer="historyCustomer"
        :history-question-list="historyQuestionList"
        :current-question="currentQuestion"
        @click-question="toQuestionDetailPage"
    >
    </history>
  </div>
</template>

<script>
import history from "@/views/questionHistory/components/history";
import {getHistory} from "@/api/question";

export default {
  name: "index",
  components: {
    history
  },
  data() {
    return {
      currentQuestion: {
        questionId: '',
      },
      historyQuestionList: [],
      historyCustomer: {},
    };
  },
  created() {
    if (this.$route.params.questionId) {
      this.currentQuestion.questionId = this.$route.params.questionId;
      this.getHistory();
    }
  },
  mounted() {
  },
  methods: {
    // 获取历史记录
    getHistory() {
      if (this.currentQuestion.questionId) {
        getHistory(this.currentQuestion.questionId)
          .then(res => {
            if (res.error === 0) {
              this.historyQuestionList = res.data;
              this.historyCustomer  = res.info.customer;
            } else {
              this.$errorMessage(res.message);
              console.log('获取历史记录错误', res.message)

            }
          })
          .catch(error => {
            this.$errorMessage('网络错误');
            console.log('获取历史记录错误', error)
          })
      }
    },
    toQuestionDetailPage(question) {
      this.$router.replace({
        name: 'questionDetail',
        params: {
          questionId: question.questionId,
        }
      });
    },
  }

}
</script>

<style scoped lang="less">

</style>

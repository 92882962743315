import request from "@/utils/request";

export function getBindAccounts() {
  return request({
    url: 'bind/status',
  })
}

export function bind(authCode) {
  return request({
    url: '/bind',
    method: 'post',
    data: {
      authCode
    }
  })
}

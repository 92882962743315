<template>
  <div class="wrap">
    <div class="header">
      <div class="avatar">
        <img alt :src="userInfo.avatar" />
      </div>
      <div class="text">
        <div class="line">Hello，欢迎使用</div>
        <div class="line">MinoTrend即时消息服务</div>
      </div>
    </div>
    <div class="tips" v-show="!userInfo.subscribe">
      <div class="text">
        请关注公众号，否则不能接收通知
      </div>
      <van-icon size=".8rem" :name="require('@/assets/images/refresh.svg')" @click="refreshUserInfo" />
    </div>
    <template v-if="bindAccounts.length && hasExpire">
      <div class="tips" v-if="isAllExpire">
        <div class="text">
          您的即时消息功能已到期，请前往PC后台续费
        </div>
      </div>
      <div class="tips" v-else>
        <div class="text">
          您的部分即时消息功能已到期
        </div>
        <div class="more" @click="expireDetailVisible = true">详情</div>
      </div>
    </template>
    <div class="bind-status">
      <div class="bind-accounts" v-show="bindAccounts.length">
        <div class="accounts-box">
          <div class="text">已绑定店铺：</div>
          <div class="accounts">
            {{ bindAccountsNameText }}
          </div>
        </div>
        <div class="right" @click="toBindPage" >
          <img :src="require('@/assets/images/add.png')" alt="">
        </div>
      </div>
      <div class="bind-nothing" v-show="!bindAccounts.length">
        <div class="text">
          点击此处绑定店铺
        </div>
        <van-icon size="1.6rem" :name="require('@/assets/images/right.svg')" @click="toBindPage" />
      </div>
    </div>

    <div class="message-num-wrap">
      <div class="title tt-wrap">
        <div class="tl">
          <div class="icon">
            <img :src="require('@/assets/images/title-icon.png')" alt="">
          </div>
          <span>询盘管理</span>
        </div>
        <div class="rl">
          <img class="update-question" :src="require('@/assets/images/update-question-icon.png')" alt=""  @click="updateAllTipsShow = true">
        </div>
      </div>
      <div class="message-num question-type" :class="{'disabled': this.isAllQuestionExpire}">
        <div class="message un-handle" @click="toQuestionPage(-1, unHandleQuestionsNum)">
          <div class="text">待回复的询盘</div>
          <div class="num">({{ unHandleQuestionsNum }})</div>
        </div>
        <div class="message handle" @click="toQuestionPage(1, handledQuestionsNum)">
          <div class="text">已回复的询盘</div>
          <div class="num">({{ handledQuestionsNum }})</div>
        </div>
      </div>
    </div>

    <div class="message-num-wrap">
      <div class="title tt-wrap">
        <div class="tl">
          <div class="icon">
            <img :src="require('@/assets/images/title-icon.png')" alt="">
          </div>
          <span>售后管理</span>
        </div>
        <div class="rl"></div>
      </div>
      <div class="message-num message-type" :class="{'disabled': this.isAllMessageExpire}">
        <template>
          <div class="message un-handle" @click="toMessagePage(-1, unHandleMessagesNum)">
            <div class="text">待回复的消息</div>
            <div class="num">({{ unHandleMessagesNum }})</div>
          </div>
        </template>
        <template>
          <div class="message handle" @click="toMessagePage(1, handledMessagesNum)">
            <div class="text">已回复的消息</div>
            <div class="num">({{ handledMessagesNum }})</div>
          </div>
        </template>
      </div>
    </div>

    <van-dialog
        v-model="updateAllTipsShow"
        title="同步所有询盘状态？"
        show-cancel-button
        @confirm="updateUnAnswerQuestion"
    >
      <div class="all" style="font-size: .8rem;text-align: left;padding: .3rem 1rem;">
        <div class="mm" style="padding: .1rem 0;">*在不同设备/平台回复的内容将得到更新同步</div>
<!--
        <div class="dd" style="padding: .1rem 0;font-weight: bold; color: red;">*美客多后台中将不再显示当前未回复的Questions数量</div>
-->
      </div>
    </van-dialog>

    <chat></chat>

    <van-dialog
        v-model="expireDetailVisible"
        title="已到期功能"
        :show-confirm-button="false"
        :show-cancel-button="false"
        :close-on-click-overlay="true"
    >
      <div class="expire-data-wrap">
        <table class="expire-data-table">
          <tr>
            <td>店铺名</td>
            <td>到期功能</td>
            <td>到期时间</td>
          </tr>
          <tr v-for="(v,i) in expireData" :key="i">
            <td>
              <div class="name">{{ v.name }}</div>
            </td>
            <td>{{ v.functionName }}</td>
            <td>{{ v.expireTime }}</td>
          </tr>
        </table>
        <div class="more">请前往PC后台续费</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>

import {getHandleMessageStatus} from "@/api/message";
import {getBindAccounts} from "@/api/bindData";
import {refreshUserInfo} from "@/api/userInfo";
import {SET_USER_INFO} from "@/store/mutation-types";
import request from "@/utils/request";
import {showChatSystem, validUserFilter} from "@/utils/chat";
import chat from '@/components/chat'

const refreshUserInfoStorageKey = 'refreshUserInfoStorageKey2';
export default {
  name: 'index',
  components: {
    chat
  },
  data() {
    return {
      bindAccounts: [],
      unHandleQuestionsNum: 0,
      unHandleMessagesNum: 0,
      handledQuestionsNum: 0,
      handledMessagesNum: 0,
      updateAllTipsShow: false,
      // 是否显示过期详情
      expireDetailVisible: false,
    }
  },
  computed: {
    // 用户信息
    userInfo: function () {
      return this.$store.state.userInfo;
    },
    // 绑定账号的提示文字
    bindAccountsNameText: function() {
      const accountNames = [];
      this.bindAccounts.forEach(account => {
        accountNames.push(account.name);
      })
      return accountNames.join('、');
    },
    // 过期功能的表格数据
    expireData: function () {
      const expireData = [];
      this.bindAccounts.forEach(v => {
        if (v.questionIsExpire) {
          expireData.push({
            name: v.name,
            functionName: '询盘管理',
            expireTime: v.questionExpireTime,
            rowSpan: !!v.messageIsExpire,
          });
        }

        if (v.messageIsExpire) {
          expireData.push({
            name: v.name,
            functionName: '售后管理',
            expireTime: v.questionExpireTime,
            rowSpan: false,
          });
        }
      });
      return expireData;
    },
    // 有过期的功能
    hasExpire: function () {
      let hasExpire = false;
      this.bindAccounts.forEach(account => {
        if (account.questionIsExpire || account.messageIsExpire) {
          hasExpire = true;
        }
      });
      return hasExpire;
    },
    // 是否全部过期
    isAllExpire: function () {
      let isAllExpire = true;
      this.bindAccounts.forEach(account => {
        if (!account.questionIsExpire || !account.messageIsExpire) {
          isAllExpire = false;
        }
      });
      return isAllExpire;
    },
    // 是否全部问题过期
    isAllQuestionExpire: function () {
      let isAllQuestionExpire = true;
      this.bindAccounts.forEach(account => {
        if (!account.questionIsExpire) {
          isAllQuestionExpire = false;
        }
      });
      return isAllQuestionExpire;
    },
    // 是否全部售后过期
    isAllMessageExpire: function () {
      let isAllMessageExpire = true;
      this.bindAccounts.forEach(account => {
        if (!account.messageIsExpire) {
          isAllMessageExpire = false;
        }
      });
      return isAllMessageExpire;
    },
  },
  created() {
    this.getHandleMessageStatus();
    this.getBindAccountData();

    // 第一次进入刷新用户信息，每12小时刷新一次
    const nowTime = (new Date()).getTime();
    const lastTime = localStorage.getItem(refreshUserInfoStorageKey);
    if (!lastTime || nowTime - lastTime >= 12 * 3600 * 1000) {
      localStorage.setItem(refreshUserInfoStorageKey, nowTime);
      this.refreshUserInfo();
    }

  },
  mounted() {
    showChatSystem();
  },
  methods: {
    // 获取未处理的信息数量
    getHandleMessageStatus() {
      getHandleMessageStatus()
        .then(res => {
          if (res.error === 0) {
            this.unHandleQuestionsNum = res.data.unHandleQuestionsNum;
            this.unHandleMessagesNum = res.data.unHandleMessagesNum;
            this.handledQuestionsNum = res.data.handledQuestionsNum;
            this.handledMessagesNum = res.data.handledMessagesNum;
          } else {
            this.$errorMessage(res.message);
          }
        })
        .catch(error => {
          console.log('请求处理信息数量错误', error);
        })
    },

    // 获取绑定的账号
    getBindAccountData() {
      getBindAccounts()
        .then(res => {
          if (res.error === 0) {
            this.bindAccounts = res.data;
          } else {
            this.$errorMessage(res.message)
          }
        })
        .catch(error => {
          console.log('请求绑定信息失败', error)
        })
    },

    // 刷新用户信息
    refreshUserInfo() {
      refreshUserInfo()
        .then(res => {
          if (res.error === 0) {
            this.$store.commit(SET_USER_INFO, res.data);
          } else {
            this.$errorMessage(res.message);
          }
        })
        .catch(error => {
          console.log('刷新用户信息失败', error);
        })
    },

    toBindPage() {
      this.$router.push({
        name: 'bind',
      })
    },

    toQuestionPage(handle, num) {
      if (!num || num < 0) {
        return false;
      }

      this.$router.push({
        name: 'question',
        query: {
          handle,
        }
      });
    },

    toMessagePage(handle, num) {
      if (!num || num < 0) {
        return false;
      }

      this.$router.push({
        name: 'message',
        query: {
          handle,
        }
      })
    },

    // 更新全部未读信息
    updateUnAnswerQuestion() {
      this.updateAllTipsShow = false;
      const toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '同步中...',
      });

      request({
        url: 'question/un-answer/update',
        method: 'post',
        timeout: 90000,
      })
        .then(res => {
          toast.close();
          if (res.error === 0) {
            this.$successMessage('同步完成！');
            this.getHandleMessageStatus();
          } else {
            this.$errorMessage(res.message);
          }
        })
        .catch(error => {
          toast.close();
          console.log('更新全部问题错误');
          this.$errorMessage('更新失败，请稍后再试');
        })
    }

  }
}
</script>

<style lang="less" scoped>
  .wrap{
    padding: .8rem 1.5rem;
    color: #333333;
  }
  .header{
    display: flex;
    padding: 0 0 1rem 0rem;
    .avatar{
      width: 3.8rem;
      height: 3.8rem;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 1.7rem;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .text{
      text-align: left;
      padding: .4rem 0 0;
      font-size: 1.2rem;
      color: #000000;
      font-weight: bold;
    }
  }
  .tips{
    background-color: #fdefee;
    border-radius: .3rem;
    display: flex;
    justify-content: space-between;
    margin: .5rem 0;
    padding: .5rem .8rem;
    align-items: center;
    .text{
      font-size: .9rem;
      color: #666666;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .more{
      color: #ff0000;
    }
  }

  .bind-status{
    background-color: #f6f6f6;
    border-radius: .3rem;
    text-align: left;
    padding: .5rem .6rem;
    margin: .3rem 0 0;
    .bind-accounts{
      display: flex;
      justify-content: space-between;
      .accounts-box{
        flex: 1 1 auto;
        max-width: 80%;
        .text{
          font-size: .8rem;
        }
        .accounts{
          font-size: .9rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: .2rem 0;
        }
      }
      .right{
        display: flex;
        align-items: center;
        padding: 0 0 0 .5rem;
        color: #aaaaaa;
        .arrow{

        }
        img{
          width: 38px;
          height: 38px;
        }
      }
    }
    .bind-nothing{
      display: flex;
      justify-content: space-between;
      color: #666666;
      padding: .3rem .5rem;
    }
  }

  .message-num-wrap{
    margin: 1.6rem 0 0;
    .title{
      text-align: left;
      font-size: 18px;
      font-weight: bold;
    }
    .tt-wrap{
      display: flex;
      justify-content: space-between;
      .tl{
        display: flex;
        .icon{
          margin-right: 8px;
          img{
            width: 20px;
            height: 20px;
          }
        }
      }
      .rl{
        .update-question{
          width: 30px;
        }
      }
    }
    .message-num{
      background-color: #fdf4e9;
      border-radius: .3rem;
      display: flex;
      padding: 1rem 2rem;
      justify-content: space-between;
      margin-top: .1rem;
      &.disabled{
        filter: contrast(80%);
      }
      &.message-type{
        background-color: #e5f0f0;
      }
      .message{
        background-color: #ffffff;
        padding: 1.2rem .6rem;
        border-radius: .2rem;
        width: 7.8rem;
        height: 5.2rem;
        box-sizing: border-box;
        color: #000000;
        &.handle{
          color: #828282;
        }
        &.plan{
          padding: .8rem .6rem;
          position: relative;
          img{
            position: absolute;
            top: .6rem;
            left: 1.6rem;
            z-index: 99;
            display: flex;
            &.transparent{
              opacity: .3;
            }
          }
          .text{
            position: absolute;
            z-index: 999;
            top: 1.2rem;
            left: 1.6rem;
          }
        }
        .text{
          padding: 0 0 .1rem;
        }
        img{
          width: 4.5rem;
          height: 4.5rem;
        }
      }
    }
  }
  // 背景图
  .message-num-wrap{
    .message-num{
      .message{
        background-size: 36%;
        background-repeat: no-repeat;
        background-position: right 3px bottom 3px;
      }
    }
  }
  .question-type{
    .handle{
      background-image: url("~@/assets/images/icon-question-handle.png");
    }
    .un-handle{
      background-image: url("~@/assets/images/icon-question-unhandle.png");
    }
  }
  .message-type{
    .handle{
      background-image: url("~@/assets/images/icon-message-handle.png");
    }
    .un-handle{
      background-image: url("~@/assets/images/icon-message-unhandle.png");
    }
  }
  .expire-data-wrap{
    display: table;
    margin: 10px auto 20px;
    padding: 0 10px;
    font-size: 14px;
    .expire-data-table{
      border-collapse: collapse;
      border: 1px solid #000000;
      tr{
        td{
          border: 1px solid #000000;
          padding: 6px 12px;
          .name{
            width: 90px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .more{
      text-align: left;
      color: #4bb6f2;
      margin: 5px 0 0;
    }
  }
</style>
